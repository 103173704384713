<template>
    <section class="px-5">
        <div class="row mx-0 my-2">
            <div class="col-12 text-gris f-18 p-0">
                Seleccione Lectores
            </div>
        </div>
        <div class="row mx-0 mt-3 aling-items-start">
            <div class="d-flex  align-items-center  w-100 mb-2">
                <el-select v-model="value" filterable size="small" class="w-100 input-select" @change="agregarUsuario">
                    <el-option
                    v-for="(item) in usuarios"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    :disabled="item.disabled"
                    />
                </el-select>
                <div class="rounded-circle bg-general cr-pointer buscar">
                    <el-tooltip
                    effect="light"
                    placement="bottom"
                    content="Busqueda por Etiquetas"
                    >
                        <i class="icon-search text-white f-13 mb-1 " @click="buscarPorEtiqueta" />
                    </el-tooltip>
                </div>
            </div>
            <div v-for="(l,index) in usuarios_seleccionados" :key="`key1${index}`" class="col-11 mt-2 p-0">
                <div class="row mx-0 border px-1 a-center" style="border-radius:28px;background-color:#F8F8F8;">
                    <img :src="l.imagen" height="35" width="35" class="rounded-circle" style="" />
                    <div class="col tres-puntos text-gris f-15">
                        {{ l.nombre }}
                    </div>
                    <div class=" p-0">
                        <!--se llena con datos del backend ya que la consulta no lo trae-->
                        <p class=" text-end text-general f-16 mr-2">25%</p>
                    </div>
                    <i class="icon-cancel f-20 text-general cr-pointer" @click="abrirRemoverLector(l.id_user)" />
                </div>
            </div>
        </div>
        <modal-buscar-por-etiquetas ref="buscarLectores" />
        <modal-eliminar-lectores ref="removerLector" titulo="¿Está seguro de eliminar este lector?" eliminar @eliminar="removerLector" /> 
    </section>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'
export default {
    components:{
        modalBuscarPorEtiquetas: () => import('../../partials/modalBuscarPorEtiquetas'),
        modalEliminarLectores : () => import ('../../../../components/modal/eliminar')
    },
    data(){
        return {
            opened: false,
            buscar: '',
            checked: false,
            select_all: false,

            value:null,
            usuarios:[],
            usuarios_seleccionados:[],      
            removerId : ''
        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso)
        }
    },
    mounted(){
        this.listarLectores()
        this.selectUsuarios()
    },
    methods:{
        buscarPorEtiqueta(){
            this.$refs.buscarLectores.toggle()
        },
        async listarLectores(){
            try {
                
                const {data} = await Cursos.listarLectores(this.id_curso)
                this.usuarios_seleccionados = data.data.lectores

            } catch (error){
                this.error_catch(error)
            }
        },
        async selectUsuarios(){
            try {
                const {data} = await Cursos.selectUsuarios(this.id_curso,1) // tipo 1 => lector
                this.usuarios = data.data.usuarios

            } catch (error){
                this.error_catch(error)
            }
        },
        agregarUsuario(id){
            let user = this.usuarios.find(o=>o.id === id)
            user.disabled = true
            this.usuarios_seleccionados.push(user)
            this.value = null
            this.agregarLector(id)
        },
        async agregarLector(id_user){
            try {
                const {data} = await Cursos.agregarLector(this.id_curso,id_user)
                this.notify('Éxito, Lector agregado','success')
                this.listarLectores()


            } catch (error){
                this.listarLectores()
                this.error_catch(error)
            }
        },
        abrirRemoverLector(id_user){
            this.removerId = id_user
            this.$refs.removerLector.toggle()
        },
        async removerLector(){
            try {
                const {data} =  await Cursos.removerLector(this.id_curso,this.removerId)
                this.usuarios.find(o=>o.id === this.removerId).disabled = false
                let index = this.usuarios_seleccionados.findIndex(o=>o.id_user === this.removerId)
                this.usuarios_seleccionados.splice(index, 1)
                this.notificacion('Mensaje','Lector removido')
                this.$refs.removerLector.toggle()
            } catch (error){
                this.listarLectores()
                this.error_catch(error)
            }
           
        },
    }
}
</script>

<style lang="scss" scoped>
.buscar{
    width: 34px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

</style>